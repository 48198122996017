import React from "react";
import {
  List,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  TextInput,
  Filter,
  EditButton,
  SimpleForm,
  Edit,
  DateField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Resource, NumberField,
  FunctionField,
 Datagrid,
} from "react-admin";

import { handleComposition } from "../utils/utils";
import { numberFiedDefaultOptions } from "../consts";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <TextInput label="Reference" source="reference" />
    <TextInput label="Collection" source="collection" />
    <TextInput label="Color" source="color" />
    <ReferenceInput label="Category" source="category.name" reference="articleCategory">
        <TextInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Project" source="projectId" reference="projects">
        <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput label="Traceability score" source="Traceability_score" />
  </Filter>
);


const ArticlesList = (props) => {
   return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="id" />
          <TextField source="reference" />
          <TextField source="collection" />
          <TextField source="amount" />
          <TextField source="weight" />
          <TextField source="color" />
          <ReferenceField label="Category" source="categoryId" reference="articleCategory">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Project" source="projectId" reference="projects">
            <TextField source="name" />
          </ReferenceField>

          <FunctionField
            className="article-compositon"
            label="Composition"
            render={
              record =>
              (record.composition && record.composition.length > 0)
              ? handleComposition(record.composition)
              : (<div>--</div>)
            }
          />

          <NumberField source="IndustryStandard_RawMaterial_Water" />
          <NumberField source="IndustryStandard_RawMaterial_ClimateChange" />
          <NumberField source="IndustryStandard_RawMaterial_Eutrophication" />
          <NumberField source="IndustryStandard_RawMaterial_FossilFuels" />
          <NumberField source="IndustryStandard_MaterialProc_Water" />
          <NumberField source="IndustryStandard_MaterialProc_ClimateChange" />
          <NumberField source="IndustryStandard_MaterialProc_Eutrophication" />
          <NumberField source="IndustryStandard_MaterialProc_FossilFuels" />
          <NumberField source="IndustryStandard_MaterialDFP_Water" />
          <NumberField source="IndustryStandard_MaterialDFP_ClimateChange" />
          <NumberField source="IndustryStandard_MaterialDFP_Eutrophication" />
          <NumberField source="IndustryStandard_MaterialDFP_FossilFuels" />
          <NumberField source="IndustryStandard_ArticleDFP_Water" />
          <NumberField source="IndustryStandard_ArticleDFP_ClimateChange" />
          <NumberField source="IndustryStandard_ArticleDFP_Eutrophication" />
          <NumberField source="IndustryStandard_ArticleDFP_FossilFuels" />
          <NumberField source="IndustryStandard_Manufacture_Water" />
          <NumberField source="IndustryStandard_Manufacture_ClimateChange" />
          <NumberField source="IndustryStandard_Manufacture_Eutrophication" />
          <NumberField source="IndustryStandard_Manufacture_FossilFuels" />
          <NumberField source="IndustryStandard_Transport_Water" />
          <NumberField source="IndustryStandard_Transport_ClimateChange" />
          <NumberField source="IndustryStandard_Transport_Eutrophication" />
          <NumberField source="IndustryStandard_Transport_FossilFuels" />
          <NumberField source="IndustryStandard_LaDP_Water" />
          <NumberField source="IndustryStandard_LaDP_ClimateChange" />
          <NumberField source="IndustryStandard_LaDP_Eutrophication" />
          <NumberField source="IndustryStandard_LaDP_FossilFuels" />

          <NumberField source="Traceability_cycle_stage" label="Nº life cycle stage"/>
          <NumberField source="Traceability_rmat_suppliers_traced" options={{ style: 'percent' }}
                       label="% RMat suppliers traced"/>
          <NumberField source="Traceability_article_suppliers_traced" options={{ style: 'percent' }}
                       label="% Article suppliers traced"/>
          <NumberField source="Traceability_total_suppliers_traced" options={{ style: 'percent' }}
                       label="TOTAL % suppliers traced"/>
          <NumberField source="Traceability_score" label="Traceability score"/>
          <NumberField source="Traceability_certificates_validated_material" label="Nº certificates validated Material"/>
          <NumberField source="Traceability_certificates_validated_supply" label="Nº certificates validated Supply"/>

          <TextField source="Planet" />
          <TextField source="People" />
          <TextField source="Transparency" />
          <TextField source="Circularity" />
          <TextField source="Supplier_Planet" />
          <TextField source="Supplier_People" />
          <TextField source="Supplier_Transparency" />
          <TextField source="Supplier_Circularity" />
          <TextField source="Material_Planet" />
          <TextField source="Material_People" />
          <TextField source="Material_Transparency" />
          <TextField source="Material_Circularity" />
          <TextField source="Corporate_Planet" />
          <TextField source="Corporate_People" />
          <TextField source="Corporate_Transparency" />
          <TextField source="Corporate_Circularity" />
          <TextField source="Water" />
          <TextField source="ClimateChange" />
          <TextField source="Eutrophication" />
          <TextField source="Waste" />
          <TextField source="FossilFuels" />
          <TextField source="WaterTotal" />
          <TextField source="ClimateChangeTotal" />
          <TextField source="EutrophicationTotal" />
          <TextField source="WasteTotal" />
          <TextField source="FossilFuelsTotal" />
          <TextField source="RawMaterial_Water" />
          <TextField source="RawMaterial_ClimateChange" />
          <TextField source="RawMaterial_Eutrophication" />
          <TextField source="RawMaterial_Waste" />
          <TextField source="RawMaterial_FossilFuels" />
          <TextField source="MaterialProc_Water" />
          <TextField source="MaterialProc_ClimateChange" />
          <TextField source="MaterialProc_Eutrophication" />
          <TextField source="MaterialProc_Waste" />
          <TextField source="MaterialProc_FossilFuels" />
          <TextField source="MaterialDFP_Water" />
          <TextField source="MaterialDFP_ClimateChange" />
          <TextField source="MaterialDFP_Eutrophication" />
          <TextField source="MaterialDFP_Waste" />
          <TextField source="MaterialDFP_FossilFuels" />
          <TextField source="ArticleDFP_Water" />
          <TextField source="ArticleDFP_ClimateChange" />
          <TextField source="ArticleDFP_Eutrophication" />
          <TextField source="ArticleDFP_Waste" />
          <TextField source="ArticleDFP_FossilFuels" />
          <TextField source="Manufacture_Water" />
          <TextField source="Manufacture_ClimateChange" />
          <TextField source="Manufacture_Eutrophication" />
          <TextField source="Manufacture_Waste" />
          <TextField source="Manufacture_FossilFuels" />
          <TextField source="Transport_Water" />
          <TextField source="Transport_ClimateChange" />
          <TextField source="Transport_Eutrophication" />
          <TextField source="Transport_Waste" />
          <TextField source="Transport_FossilFuels" />
          <TextField source="LaDP_Water" />
          <TextField source="LaDP_ClimateChange" />
          <TextField source="LaDP_Eutrophication" />
          <TextField source="LaDP_Waste" />
          <TextField source="LaDP_FossilFuels" />
          <TextField source="Savings_Water" />
          <TextField source="Savings_ClimateChange" />
          <TextField source="Savings_Eutrophication" />
          <TextField source="Savings_Waste" />
          <TextField source="Savings_FossilFuels" />
          <TextField source="Savings_Water_Relative" />
          <TextField source="Savings_ClimateChange_Relative" />
          <TextField source="Savings_Eutrophication_Relative" />
          <TextField source="Savings_Waste_Relative" />
          <TextField source="Savings_FossilFuels_Relative" />
          <TextField source="Equivalences_Waste" />
          <TextField source="Equivalences_Water_Total" />
          <TextField source="Equivalences_ClimateChange_Total" />
          <TextField source="Equivalences_Eutrophication_Total" />
          <TextField source="Equivalences_Waste_Total" />
          <TextField source="Equivalences_FossilFuels_Total" />
          <TextField source="Equivalences_Water_Relative" />
          <TextField source="Equivalences_ClimateChange_Relative" />
          <TextField source="Equivalences_Eutrophication_Relative" />
          <TextField source="Equivalences_Waste_Relative" />
          <TextField source="Equivalences_FossilFuels_Relative" />
          <TextField source="Traceability_People" />
          <TextField source="Traceability_Process" />
          <NumberField source="Traceability_Km" locales="fr-FR" options={{ maximumFractionDigits: 0 }}/>
          <TextField source="Traceability_Suppliers" />
          <TextField source="Traceability_Certificates" />
          <TextField source="Traceability_Km_Materials" />
          <TextField source="Traceability_Km_CMT" />
          <TextField source="Traceability_Km_Assumed" />
          <TextField source="Traceability_cycle_stage_total" />
          <NumberField source="Traceability_mat_suppliers_traced" options={{ style: 'percent' }} />
          <TextField source="imageUrl" />

          <NumberField options={numberFiedDefaultOptions} source="WC_U_A" label="Water consumption Article" />
          <NumberField options={numberFiedDefaultOptions} source="WC_U_IS" label="Water consumption Industry Standard" />

          <NumberField source="MCI_A" label="Article MCI" />
          <NumberField source="Recycled_A" label="Recycled composition in Article" />
          <NumberField source="TU_Waste_A" label="Total unrecoverable waste of Article" />
          <NumberField source="Utility_A" label="Utility of Article" />

          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowArticles = (props) => {
  return (
    <Show {...props} className="custom-width-article">
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="name" />
          <TextField source="reference" />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <TextField source="amount" />
          <TextField source="collection" />
          <TextField source="weight" />
          <TextField source="color" />
          <ReferenceField label="Category" source="categoryId" reference="articleCategory">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="imageUrl" />
          <ReferenceField label="Project" source="projectId" reference="projects">
            <TextField source="name" />
          </ReferenceField>
          <FunctionField
            label="Composition"
            render={
              record =>
              (record.composition && record.composition.length > 0)
              ? handleComposition(record.composition)
              : (<div>--</div>)
            }
          />
        </Tab>
        <Tab label="metrics">
          <TextField source="Planet" />
          <TextField source="People" />
          <TextField source="Transparency" />
          <TextField source="Circularity" />
          <TextField source="Supplier_Planet" />
          <TextField source="Supplier_People" />
          <TextField source="Supplier_Transparency" />
          <TextField source="Supplier_Circularity" />
          <TextField source="Material_Planet" />
          <TextField source="Material_People" />
          <TextField source="Material_Transparency" />
          <TextField source="Material_Circularity" />
          <TextField source="Corporate_Planet" />
          <TextField source="Corporate_People" />
          <TextField source="Corporate_Transparency" />
          <TextField source="Corporate_Circularity" />
          <TextField source="Water" />
          <TextField source="ClimateChange" />
          <TextField source="Eutrophication" />
          <TextField source="Waste" />
          <TextField source="FossilFuels" />
          <TextField source="WaterTotal" />
          <TextField source="ClimateChangeTotal" />
          <TextField source="EutrophicationTotal" />
          <TextField source="WasteTotal" />
          <TextField source="FossilFuelsTotal" />
          <TextField source="RawMaterial_Water" />
          <TextField source="RawMaterial_ClimateChange" />
          <TextField source="RawMaterial_Eutrophication" />
          <TextField source="RawMaterial_Waste" />
          <TextField source="RawMaterial_FossilFuels" />
          <TextField source="MaterialProc_Water" />
          <TextField source="MaterialProc_ClimateChange" />
          <TextField source="MaterialProc_Eutrophication" />
          <TextField source="MaterialProc_Waste" />
          <TextField source="MaterialProc_FossilFuels" />
          <TextField source="MaterialDFP_Water" />
          <TextField source="MaterialDFP_ClimateChange" />
          <TextField source="MaterialDFP_Eutrophication" />
          <TextField source="MaterialDFP_Waste" />
          <TextField source="MaterialDFP_FossilFuels" />
          <TextField source="ArticleDFP_Water" />
          <TextField source="ArticleDFP_ClimateChange" />
          <TextField source="ArticleDFP_Eutrophication" />
          <TextField source="ArticleDFP_Waste" />
          <TextField source="ArticleDFP_FossilFuels" />
          <TextField source="Manufacture_Water" />
          <TextField source="Manufacture_ClimateChange" />
          <TextField source="Manufacture_Eutrophication" />
          <TextField source="Manufacture_Waste" />
          <TextField source="Manufacture_FossilFuels" />
          <TextField source="Transport_Water" />
          <TextField source="Transport_ClimateChange" />
          <TextField source="Transport_Eutrophication" />
          <TextField source="Transport_Waste" />
          <TextField source="Transport_FossilFuels" />
          <TextField source="LaDP_Water" />
          <TextField source="LaDP_ClimateChange" />
          <TextField source="LaDP_Eutrophication" />
          <TextField source="LaDP_Waste" />
          <TextField source="LaDP_FossilFuels" />
          <TextField source="Savings_Water" />
          <TextField source="Savings_ClimateChange" />
          <TextField source="Savings_Eutrophication" />
          <TextField source="Savings_Waste" />
          <TextField source="Savings_FossilFuels" />
          <TextField source="Savings_Water_Relative" />
          <TextField source="Savings_ClimateChange_Relative" />
          <TextField source="Savings_Eutrophication_Relative" />
          <TextField source="Savings_Waste_Relative" />
          <TextField source="Savings_FossilFuels_Relative" />
          <TextField source="Equivalences_Waste" />
          <TextField source="Equivalences_Water_Total" />
          <TextField source="Equivalences_ClimateChange_Total" />
          <TextField source="Equivalences_Eutrophication_Total" />
          <TextField source="Equivalences_Waste_Total" />
          <TextField source="Equivalences_FossilFuels_Total" />
          <TextField source="Equivalences_Water_Relative" />
          <TextField source="Equivalences_ClimateChange_Relative" />
          <TextField source="Equivalences_Eutrophication_Relative" />
          <TextField source="Equivalences_Waste_Relative" />
          <TextField source="Equivalences_FossilFuels_Relative" />
          <TextField source="Traceability_People" />
          <TextField source="Traceability_Process" />
          <NumberField source="Traceability_Km" locales="fr-FR" options={{ maximumFractionDigits: 0 }} />
          <TextField source="Traceability_Suppliers" />
          <TextField source="Traceability_Certificates" />
          <TextField source="Traceability_Km_Materials" />
          <TextField source="Traceability_Km_CMT" />
          <TextField source="Traceability_Km_Assumed" />
          <TextField source="Traceability_cycle_stage_total" />
          <NumberField source="Traceability_mat_suppliers_traced" options={{ style: 'percent' }} />
          <NumberField source="Traceability_rmat_suppliers_traced" options={{ style: 'percent' }} label="% RMat suppliers traced"/>
          <NumberField source="Traceability_cycle_stage" label="Nº life cycle stage"/>
          <NumberField source="Traceability_certificates_validated_material" label="Nº certificates validated Material"/>
          <NumberField source="Traceability_certificates_validated_supply" label="Nº certificates validated Supply"/>
          <NumberField source="Traceability_article_suppliers_traced" options={{ style: 'percent' }} label="% Article suppliers traced"/>
          <NumberField source="Traceability_total_suppliers_traced" options={{ style: 'percent' }} label="TOTAL % suppliers traced"/>
          <NumberField source="Traceability_score" label="Traceability score"/>

          <NumberField source="MCI_A" label="Article MCI" />
          <NumberField source="Recycled_A" label="Recycled composition in Article" />
          <NumberField source="TU_Waste_A" label="Total unrecoverable waste of Article" />
          <NumberField source="Utility_A" label="Utility of Article" />

          <NumberField options={numberFiedDefaultOptions} source="WC_U_A" label="Water consumption Article" />
          <NumberField options={numberFiedDefaultOptions} source="WC_U_IS" label="Water consumption Industry Standard" />
        </Tab>
        <Tab label="Unitary industry standard impact">
            <NumberField label="Water scarcity (Extraction)" source="IndustryStandard_RawMaterial_Water" />
            <NumberField label="Global warming (Extraction)" source="IndustryStandard_RawMaterial_ClimateChange" />
            <NumberField label="Eutrophication (Extraction)" source="IndustryStandard_RawMaterial_Eutrophication" />
            <NumberField label="Abiotic depletion (Extraction)" source="IndustryStandard_RawMaterial_FossilFuels" />
            <NumberField label="Water scarcity (Processing)" source="IndustryStandard_MaterialProc_Water" />
            <NumberField label="Global warming (Processing)" source="IndustryStandard_MaterialProc_ClimateChange" />
            <NumberField label="Eutrophication (Processing)" source="IndustryStandard_MaterialProc_Eutrophication" />
            <NumberField label="Abiotic depletion (Processing)" source="IndustryStandard_MaterialProc_FossilFuels" />
            <NumberField label="Water scarcity (Material finishing)" source="IndustryStandard_MaterialDFP_Water" />
            <NumberField label="Global warming (Material finishing)" source="IndustryStandard_MaterialDFP_ClimateChange" />
            <NumberField label="Eutrophication (Material finishing)" source="IndustryStandard_MaterialDFP_Eutrophication" />
            <NumberField label="Abiotic depletion (Material finishing)" source="IndustryStandard_MaterialDFP_FossilFuels" />
            <NumberField label="Water scarcity (Article finishing)" source="IndustryStandard_ArticleDFP_Water" />
            <NumberField label="Global warming (Article finishing)" source="IndustryStandard_ArticleDFP_ClimateChange" />
            <NumberField label="Eutrophication (Article finishing)" source="IndustryStandard_ArticleDFP_Eutrophication" />
            <NumberField label="Abiotic depletion (Article finishing)" source="IndustryStandard_ArticleDFP_FossilFuels" />
            <NumberField label="Water scarcity (Manufacturing)" source="IndustryStandard_Manufacture_Water" />
            <NumberField label="Global warming (Manufacturing)" source="IndustryStandard_Manufacture_ClimateChange" />
            <NumberField label="Eutrophication (Manufacturing)" source="IndustryStandard_Manufacture_Eutrophication" />
            <NumberField label="Abiotic depletion (Manufacturing)" source="IndustryStandard_Manufacture_FossilFuels" />
            <NumberField label="Water scarcity (Transport)" source="IndustryStandard_Transport_Water" />
            <NumberField label="Global warming (Transport)" source="IndustryStandard_Transport_ClimateChange" />
            <NumberField label="Eutrophication (Transport)" source="IndustryStandard_Transport_Eutrophication" />
            <NumberField label="Abiotic depletion (Transport)" source="IndustryStandard_Transport_FossilFuels" />
            <NumberField label="Water scarcity (Packaging)" source="IndustryStandard_LaDP_Water" />
            <NumberField label="Global warming (Packaging)" source="IndustryStandard_LaDP_ClimateChange" />
            <NumberField label="Eutrophication (Packaging)" source="IndustryStandard_LaDP_Eutrophication" />
            <NumberField label="Abiotic depletion (Packaging)" source="IndustryStandard_LaDP_FossilFuels" />
          </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const EditArticle = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm className="custom-width-article">
        <TabbedShowLayout>
          <Tab label="summary">
              <TextInput source="name" />
              <TextInput source="reference" required={true} />
              <TextInput source="amount" required={false} />
              <TextInput source="collection" />
              <TextInput source="weight" />
              <TextInput source="color" />
              <ReferenceInput label="Category" source="categoryId" reference="articleCategory">
                <SelectInput source="name" />
              </ReferenceInput>
              <TextInput source="imageUrl" />
              <ReferenceInput label="Project" source="projectId" reference="projects">
                <SelectInput disabled optionText="name" />
              </ReferenceInput>
          </Tab>
          <Tab label="metrics">
            <TextField source="Planet" />
            <TextField source="People" />
            <TextField source="Transparency" />
            <TextField source="Circularity" />
            <TextField source="Supplier_Planet" />
            <TextField source="Supplier_People" />
            <TextField source="Supplier_Transparency" />
            <TextField source="Supplier_Circularity" />
            <TextField source="Material_Planet" />
            <TextField source="Material_People" />
            <TextField source="Material_Transparency" />
            <TextField source="Material_Circularity" />
            <TextField source="Corporate_Planet" />
            <TextField source="Corporate_People" />
            <TextField source="Corporate_Transparency" />
            <TextField source="Corporate_Circularity" />
            <TextField source="Water" />
            <TextField source="ClimateChange" />
            <TextField source="Eutrophication" />
            <TextField source="Waste" />
            <TextField source="FossilFuels" />
            <TextField source="WaterTotal" />
            <TextField source="ClimateChangeTotal" />
            <TextField source="EutrophicationTotal" />
            <TextField source="WasteTotal" />
            <TextField source="FossilFuelsTotal" />
            <TextField source="RawMaterial_Water" />
            <TextField source="RawMaterial_ClimateChange" />
            <TextField source="RawMaterial_Eutrophication" />
            <TextField source="RawMaterial_Waste" />
            <TextField source="RawMaterial_FossilFuels" />
            <TextField source="MaterialProc_Water" />
            <TextField source="MaterialProc_ClimateChange" />
            <TextField source="MaterialProc_Eutrophication" />
            <TextField source="MaterialProc_Waste" />
            <TextField source="MaterialProc_FossilFuels" />
            <TextField source="MaterialDFP_Water" />
            <TextField source="MaterialDFP_ClimateChange" />
            <TextField source="MaterialDFP_Eutrophication" />
            <TextField source="MaterialDFP_Waste" />
            <TextField source="MaterialDFP_FossilFuels" />
            <TextField source="ArticleDFP_Water" />
            <TextField source="ArticleDFP_ClimateChange" />
            <TextField source="ArticleDFP_Eutrophication" />
            <TextField source="ArticleDFP_Waste" />
            <TextField source="ArticleDFP_FossilFuels" />
            <TextField source="Manufacture_Water" />
            <TextField source="Manufacture_ClimateChange" />
            <TextField source="Manufacture_Eutrophication" />
            <TextField source="Manufacture_Waste" />
            <TextField source="Manufacture_FossilFuels" />
            <TextField source="Transport_Water" />
            <TextField source="Transport_ClimateChange" />
            <TextField source="Transport_Eutrophication" />
            <TextField source="Transport_Waste" />
            <TextField source="Transport_FossilFuels" />
            <TextField source="LaDP_Water" />
            <TextField source="LaDP_ClimateChange" />
            <TextField source="LaDP_Eutrophication" />
            <TextField source="LaDP_Waste" />
            <TextField source="LaDP_FossilFuels" />
            <TextField source="Savings_Water" />
            <TextField source="Savings_ClimateChange" />
            <TextField source="Savings_Eutrophication" />
            <TextField source="Savings_Waste" />
            <TextField source="Savings_FossilFuels" />
            <TextField source="Savings_Water_Relative" />
            <TextField source="Savings_ClimateChange_Relative" />
            <TextField source="Savings_Eutrophication_Relative" />
            <TextField source="Savings_Waste_Relative" />
            <TextField source="Savings_FossilFuels_Relative" />
            <TextField source="Equivalences_Waste" />
            <TextField source="Equivalences_Water_Total" />
            <TextField source="Equivalences_ClimateChange_Total" />
            <TextField source="Equivalences_Eutrophication_Total" />
            <TextField source="Equivalences_Waste_Total" />
            <TextField source="Equivalences_FossilFuels_Total" />
            <TextField source="Equivalences_Water_Relative" />
            <TextField source="Equivalences_ClimateChange_Relative" />
            <TextField source="Equivalences_Eutrophication_Relative" />
            <TextField source="Equivalences_Waste_Relative" />
            <TextField source="Equivalences_FossilFuels_Relative" />
            <TextField source="Traceability_People" />
            <TextField source="Traceability_Process" />
            <NumberField source="Traceability_Km" locales="fr-FR" options={{ maximumFractionDigits: 0 }} />
            <TextField source="Traceability_Suppliers" />
            <TextField source="Traceability_Certificates" />
            <TextField source="Traceability_Km_Materials" />
            <TextField source="Traceability_Km_CMT" />
            <TextField source="Traceability_Km_Assumed" />
            <TextField source="Traceability_cycle_stage_total" />
            <NumberField source="Traceability_mat_suppliers_traced" options={{ style: 'percent' }} />
            <NumberField source="Traceability_rmat_suppliers_traced" options={{ style: 'percent' }} label="% RMat suppliers traced"/>
            <NumberField source="Traceability_cycle_stage" label="Nº life cycle stage"/>
            <NumberField source="Traceability_certificates_validated_material" label="Nº certificates validated Material"/>
            <NumberField source="Traceability_certificates_validated_supply" label="Nº certificates validated Supply"/>
            <NumberField source="Traceability_article_suppliers_traced" options={{ style: 'percent' }} label="% Article suppliers traced"/>
            <NumberField source="Traceability_total_suppliers_traced" options={{ style: 'percent' }} label="TOTAL % suppliers traced"/>
            <NumberField source="Traceability_score" label="Traceability score"/>

            <NumberField source="MCI_A" label="Article MCI" />
            <NumberField source="Recycled_A" label="Recycled composition in Article" />
            <NumberField source="TU_Waste_A" label="Total unrecoverable waste of Article" />
            <NumberField source="Utility_A" label="Utility of Article" />

            <NumberField options={numberFiedDefaultOptions} source="WC_U_A" label="Water consumption Article" />
            <NumberField options={numberFiedDefaultOptions} source="WC_U_IS" label="Water consumption Industry Standard" />
          </Tab>
          <Tab label="Unitary industry standard impact">
            <NumberField label="Water scarcity (Extraction)" source="IndustryStandard_RawMaterial_Water" />
            <NumberField label="Global warming (Extraction)" source="IndustryStandard_RawMaterial_ClimateChange" />
            <NumberField label="Eutrophication (Extraction)" source="IndustryStandard_RawMaterial_Eutrophication" />
            <NumberField label="Abiotic depletion (Extraction)" source="IndustryStandard_RawMaterial_FossilFuels" />
            <NumberField label="Water scarcity (Processing)" source="IndustryStandard_MaterialProc_Water" />
            <NumberField label="Global warming (Processing)" source="IndustryStandard_MaterialProc_ClimateChange" />
            <NumberField label="Eutrophication (Processing)" source="IndustryStandard_MaterialProc_Eutrophication" />
            <NumberField label="Abiotic depletion (Processing)" source="IndustryStandard_MaterialProc_FossilFuels" />
            <NumberField label="Water scarcity (Material finishing)" source="IndustryStandard_MaterialDFP_Water" />
            <NumberField label="Global warming (Material finishing)" source="IndustryStandard_MaterialDFP_ClimateChange" />
            <NumberField label="Eutrophication (Material finishing)" source="IndustryStandard_MaterialDFP_Eutrophication" />
            <NumberField label="Abiotic depletion (Material finishing)" source="IndustryStandard_MaterialDFP_FossilFuels" />
            <NumberField label="Water scarcity (Article finishing)" source="IndustryStandard_ArticleDFP_Water" />
            <NumberField label="Global warming (Article finishing)" source="IndustryStandard_ArticleDFP_ClimateChange" />
            <NumberField label="Eutrophication (Article finishing)" source="IndustryStandard_ArticleDFP_Eutrophication" />
            <NumberField label="Abiotic depletion (Article finishing)" source="IndustryStandard_ArticleDFP_FossilFuels" />
            <NumberField label="Water scarcity (Manufacturing)" source="IndustryStandard_Manufacture_Water" />
            <NumberField label="Global warming (Manufacturing)" source="IndustryStandard_Manufacture_ClimateChange" />
            <NumberField label="Eutrophication (Manufacturing)" source="IndustryStandard_Manufacture_Eutrophication" />
            <NumberField label="Abiotic depletion (Manufacturing)" source="IndustryStandard_Manufacture_FossilFuels" />
            <NumberField label="Water scarcity (Transport)" source="IndustryStandard_Transport_Water" />
            <NumberField label="Global warming (Transport)" source="IndustryStandard_Transport_ClimateChange" />
            <NumberField label="Eutrophication (Transport)" source="IndustryStandard_Transport_Eutrophication" />
            <NumberField label="Abiotic depletion (Transport)" source="IndustryStandard_Transport_FossilFuels" />
            <NumberField label="Water scarcity (Packaging)" source="IndustryStandard_LaDP_Water" />
            <NumberField label="Global warming (Packaging)" source="IndustryStandard_LaDP_ClimateChange" />
            <NumberField label="Eutrophication (Packaging)" source="IndustryStandard_LaDP_Eutrophication" />
            <NumberField label="Abiotic depletion (Packaging)" source="IndustryStandard_LaDP_FossilFuels" />
          </Tab>
        </TabbedShowLayout>
      </SimpleForm>
    </Edit>
  );
};


const articlesResource = () => <Resource
  name="articles"
  options={{ label: 'Article', menuGroup: 'Articles'}}
  list={ArticlesList}
  // create={CreateArticleProcess}
  edit={EditArticle}
  show={ShowArticles} />;

export default articlesResource;
