import "./App.css";
import { Admin } from "react-admin";
import authProvider from "./providers/authProvider";
import usersResource from "./resources/UserResource";
import accountsResource from "./resources/AccountResource";
import projectsResource from "./resources/ProjectResource";
import articlesResource from "./resources/ArticleResource";
import { TokenContextProvider, useTokenOps } from "./components/TokenContext";
import useDataProvider from "./providers/dataProvider";
import Menu from "./components/Menu";


import accountBestPracticesResource from "./resources/AccountBestPracticesResource";
import accountFeaturesResource from "./resources/accountFeaturesResource";
import AppLayout from "./components/AppLayout";
import articleCategoryResource from "./resources/ArticleCategoryResource";
import articleGroupResource from "./resources/ArticleGroupResource";
import articleMaterialResource from "./resources/ArticleMaterialResource";
import articlePackagingResource from "./resources/ArticlePackagingResource";
import articleProcessResource from "./resources/ArticleProcessResource";
import articleSupplierResource from "./resources/ArticleSupplierResource";
import bestPracticesResource from "./resources/BestPracticesResource";
import certificatesResource from "./resources/CertificatesResource";
import endofuseGroupResource from "./resources/EndofuseGroupResource";
import entityResource from "./resources/EntityResource";
import equivalencesResource from "./resources/EquivalencesResource";
import evaluationCategoryResource from "./resources/EvaluationCategoryResource";
import evaluationGroupResource from "./resources/EvaluationGroupResource";
import featuresResource from "./resources/FeaturesResource";
import glossaryResource from "./resources/GlossaryResource";
import hazardousSusbtancesResource from "./resources/HazardousSusbtancesResource";
import lcaCategoryResource from "./resources/LcaCategoryResource";
import lcaGroupResource from "./resources/LcaGroupResource";
import libraryResource from "./resources/LibraryResource";
import materialCertificateResource from "./resources/MaterialCertificateResource";
import materialProcessResource from "./resources/MaterialProcessResource";
import materialResource from "./resources/MaterialResource";
import materialsApplicationResource from "./resources/MaterialsApplicationResource";
import materialsCategoryResource from "./resources/MaterialsCategoryResource";
import materialsGroupResource from "./resources/MaterialsGroupResource";
import originsResource from "./resources/OriginsResource";
import packagingCategoryResource from "./resources/PackagingCategoryResource";
import packagingGroupResource from "./resources/PackagingGroupResource";
// import packagingProcessResource from "./resources/PackagingProcessResource";
// import packagingResource from "./resources/PackagingResource";
import processMaterialsCategoryResource from "./resources/ProcessMaterialsCategoryResource";
import processOriginResource from "./resources/ProcessOriginResource";
import processRawMaterialCategoryResource from "./resources/ProcessRawMaterialCategoryResource";
import processResource from "./resources/ProcessResource";
import rawMaterialCategoryResource from "./resources/RawMaterialsCategoryResource";
import rawMaterialGroupResource from "./resources/RawMaterialsGroupResource";
import rawMaterialMaterialProcessesResource from "./resources/RawMaterialMaterialProcessesResource";
import retailGroupResource from "./resources/RetailGroupResource";
import retailPackagingProcessesResource from "./resources/RetailPackagingProcessesResource";
import retailPackagingResource from "./resources/RetailPackagingResource";
import supplierCertificateResource from "./resources/SupplierCertificateResource";
import supplierResource from "./resources/SupplierResource";
import transportOriginResource from "./resources/TransportOriginResource";
import transportResource from "./resources/TransportResource";
import useGroupResource from "./resources/UseGroupResource";
import viewsResource from "./resources/ViewResource";
import articleHazardousResource from "./resources/ArticleHazardousResource";
import uploadStatusResource from "./resources/uploadStatusResource";
import digitalizationDataResource from "./resources/DigitalizationDataResource";
import digitalizationDataAccountResource from "./resources/DigitalizationDataAccountResource";

const AdminApp = () => {
  const tokenOps = useTokenOps();
  const dataProvider = useDataProvider(tokenOps);
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider(tokenOps)}
      menu={Menu}
      appLayout={AppLayout}
    >
      {/* Accounts */}
      {accountsResource()}
      {accountFeaturesResource()}
      {accountBestPracticesResource()}
      {featuresResource()}
      {libraryResource()}
      {usersResource()}
      {digitalizationDataAccountResource()}

      {/* Projects */}
      {projectsResource()}
      {viewsResource()}

      {/* Articles */}
      {articlesResource()}
      {articleHazardousResource()}
      {articleCategoryResource()}
      {articleGroupResource()}
      {articleMaterialResource()}
      {articleSupplierResource()}
      {articlePackagingResource()}
      {articleProcessResource()}
      {entityResource()}
      {digitalizationDataResource()}

      {/* Materials */}
      {materialResource()}
      {materialCertificateResource()}
      {materialProcessResource()}

      {/* Suppliers */}
      {supplierResource()}
      {supplierCertificateResource()}

      {/* Packaging */}
      {/* {packagingResource()}
      {packagingProcessResource()} */}
      {retailPackagingResource()}

      {/* Glossary */}
      {glossaryResource()}

      {/* Bcome */}
      {bestPracticesResource()}
      {certificatesResource()}
      {endofuseGroupResource()}
      {equivalencesResource()}
      {evaluationCategoryResource()}
      {evaluationGroupResource()}
      {hazardousSusbtancesResource()}
      {lcaCategoryResource()}
      {lcaGroupResource()}
      {materialsApplicationResource()}
      {materialsCategoryResource()}
      {materialsGroupResource()}
      {originsResource()}
      {packagingCategoryResource()}
      {packagingGroupResource()}
      {processMaterialsCategoryResource()}
      {processOriginResource()}
      {processRawMaterialCategoryResource()}
      {processResource()}
      {rawMaterialCategoryResource()}
      {rawMaterialGroupResource()}
      {rawMaterialMaterialProcessesResource()}
      {retailGroupResource()}
      {retailPackagingProcessesResource()}
      {transportOriginResource()}
      {transportResource()}
      {useGroupResource()}

      {/* Upload status */}
      {uploadStatusResource()}

    </Admin>
  );
}

const App = () => (
  <TokenContextProvider>
    <AdminApp />
  </TokenContextProvider>
);

export default App;
